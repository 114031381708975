.central-do-assinante {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100vh;
}

.conteudo-central-assinante {
    width: 50vw;
    padding: 30px;
 
}
.conteudo-central-assinante h1 {
    font-size: 3rem;
    color: #fb7c05;
}
@media screen and (max-width: 1058px){
    .conteudo-central-assinante h1 {
        font-size: 2rem;}
}
.conteudo-central-assinante p {
    font-size: 15px;
}
.meninaCentral {
    width: 400px;
}
.container-foto {
    display: flex;
    justify-content: center;
}
.conteudo-central-assinante ul li {
    font-size: 20px;
    line-height: 10px;
}

ul {
    margin-top: 30px;
    margin-bottom: 30px;
}

@media screen and (max-width: 958px){
    .nav {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }
    .title {
        margin-left: 10px;
    }
   
    .central-do-assinante {
        display: block;
        justify-content: center;
        align-items: center;
    }
    .conteudo-central-assinante h1 {
        text-align: center;
        font-size: 28px;
        font-weight: 900;
        line-height: 50px;
        
    }
    .conteudo-central-assinante p {
        padding-top: 10px;
        text-align: justify;
        margin-bottom: 190px;
        font-size: medium;
        color: white;
    }
    .conteudo-central-assinante {
        width: 100vw;
        padding: 15px;
    }
    .butao-assinante {
        display: flex;
        justify-content: center;
    }
    .lista  ul{
        list-style: circle;}

    .lista li {
        display: flex;
        font-size: 30px;
        line-height: 40px;
      
        
    }
    .meninaCentral {
        margin-top: -250px;
    }
    .conteudo-central-assinante ul li {
        display: none;
    }
}

@media screen and (max-width: 958px){
.btn {
    display: none;
}

.assinante {
    display: flex;
}
}

@media screen and (max-width: 550px) {
    .central-do-assinante {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        height: 100%;
    }
}
