.content {
  padding: 30px 0;
  height: -moz-max-content;

  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6a016e;
}
.box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

}

.box-h2 {
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  color: white;
  opacity: 1;
  text-shadow: 1px 10px 20px purple;
}

.pl_img1 {
  background-image: url("../public/images/sloga.png");
}

.pl_img2 {
  background: url("../public/images/sloga2.png");
}

.pl_img3 {
  background: url("../public/images/sloga3.png");
}


.fixe-bg {
  height: 400px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

@media screen and (max-width: 558px){
  .pl_img1 {
    background-image: url("../public/images/slogaPhone1.png");
   
  }
  .pl_img2 {
    background-image: url("../public/images/slogaPhone2.png");
   
    
  }
  .pl_img3 {
    background-image: url("../public/images/slogaPhone3.png");
  
    
  }
.box-h2 {
  text-align: center;
  font-size: 59px;}

}