* {
  margin: 0;
  padding: 0;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}


body {
  background-color: white;
  font-family: "Roboto, sans-serif";
}

main {
  display: block;
}
.container-pg {
  max-width: 1600px;
  margin: 0 auto;
}
.logo {
  width: 320px;
}
.logo2 {
  width: 250px;
}
#style-color {
  color: white;
}
.garota {
  width: 330px;
  margin-left: 100px;
}
.section1 {
  display: block;
  z-index: 1;
  position: relative;
  overflow: hidden; /* Oculta o conteúdo que excede os limites */
}
#subtitle {
  margin-bottom: -20px;
}
#price {
  font-size: 50px;
}
.menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: #6a016e;
}

.assinante:hover {
  background-color: #fb7c05;
  color: white;
}
.botao-central-assinante {
  display: block;
  position: absolute;
  width: 100%;
  padding: 85px;
  margin-top: 300px;
}
.icon-assinante {
  margin-right: 5px;
}

.contact {
  font-size: 17px;
  font-weight: 900;
  border-radius: 5px;
  opacity: 1;

  width: 110px;
  height: 30px;
  background-color: #fb7c05;
  margin-right: 40px;
}
.contact:hover {
  background-color: #fb7c05;
}

.btn {
  display: flex;
}

.container h2 {
  font-size: 40px;
  font-weight: 800;
  color: #fb7c05;
}

#cl-cliente {
  padding: 15px;
}

.input {
  width: 90%;
  height: 100%;
  min-height: 60px;
  max-height: 60px;
  padding: 15px;
  border-radius: 10px;
  border: none;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  outline: none;
  color: #26003b;
  border: solid 1px #26003b;
  margin-top: -132px;
  margin-bottom: 92px;
  margin-left: 22px;
}
.assinante {
  background-color: #fb7c05;
  width: 250px;
}
.container h1 {
  font-size: 40px;
  font-weight: 200;
  color: white;
  letter-spacing: -0.2rem;
  line-height: 2.9rem;
  padding: 10px 0px 10px;
}

.container h5 {
  font-size: 30px;
  color: #fb7c05;
}
.container h3 {
  font-size: 20px;
  color: white;
  margin-bottom: 10px;
  margin-left: 50px;
}
strong {
  color: #fb7c05;
  font-weight: 800;
}
text {
  color: white;
}

.nav {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 30px;
}

.nav a {
  margin-right: 20px;
}

.btn {
  z-index: 9999;
  text-decoration: none;

  border-radius: 25px;
  transition: background-color 0.3s ease, color 0.3s ease;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-size: 18px;
  border: 0px solid white;
}
.btn2 {
  z-index: 9999;
  width: 230px;
  text-decoration: none;
  border-radius: 25px;
  transition: background-color 0.3s ease, color 0.3s ease;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  border: 0px solid white;
}

.btn:hover {
  color: #fb7c05;
  cursor: pointer;
}
.assinante:hover {
  background-color: #fb7c05;
  color: #350053;
}

li {
  font-size: 17px;
  width: initial;
  padding: 10px;
  font-weight: 700;
}

.main {
  background-size: cover;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.conteudo {
  display: inline-block;
  width: 43vw;
}

.conteudo h1 {
  margin-top: 30px;
  font-weight: 800;
  font-size: 65px;
  line-height: 4.2rem;
  width: 110%;
}

.conteudo p {
  font-size: 20px;
  text-align: justify;
  width: 90%;
  margin-left: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button-aumentar-vendas {
  color: white;
  background-color: transparent;
  border: solid 1px white;
  width: 204px;
  height: 40px;
  font-size: 20px;
  font-weight: 200;
  border-radius: 5px;
  margin-left: 100px;
  margin-top: -20px;
}
.button-aumentar-vendas:hover {
  background-color: #fb7c05;
}
.section2 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 30vh;
  background-color: #5b005e;
  z-index: 1;
  position: relative;
}

.conteudo-titulos {
  display: block;
}
.section2 h1 {
  width: 100%;
  font-size: 30px;
  font-weight: 700;
  margin-top: 20px;
  color: #fb7c05;
}

#wpp-icon {
  display: inline-block;
  margin-right: 10px;
  color: #380158;
}

.bloco img {
  background-color: white;
  padding: 10px;
}
.bloco h2 {
  font-size: 30px;
  font-weight: 900;
  color: #fb7c05;
  text-shadow: 3px 5px 2px #350053;
}
.bloco h2 span {
  font-size: 70px;
  color: #fb7c05;
}
.bloco h1 {
  padding: 10px;
  font-size: 41px;
  font-weight: 800;
  color: #350053;
  line-height: 2.9rem;
}
.bloco p {
  background-color: white;
  color: #4a4a4a;
  width: auto;
  font-size: 20px;
  text-align: left;
}

.contact-bloco {
  font-size: 20px;
  font-weight: 900;
  border-radius: 5px;
  opacity: 1;
  height: 50px;
  width: 100%;
  background-color: #0fce33;
  margin-top: 15px;
  margin-right: 40px;
}
.button:hover {
  color: #350053;
}
.contact-bloco:hover {
  color: #350053;
}
.contact:hover {
  color: #350053;
}

.contact-client {
  background: white;
  color: #4e4e4e;
  text-decoration: none;
  padding: 0.5em 0.75em;
  border-radius: 0.25em;
  text-transform: uppercase;
  font-weight: 700;
  height: 50px;
}

.contact-client:hover {
  color: #350053;
}

#contact-h2-client {
  font-size: 30px;
  font-weight: 600;
  color: rgb(23, 44, 61);
  width: 100%;
  text-align: center;
}

.client-img {
  width: 330px;
  margin-left: 60px;
  margin-bottom: 88px;
}
.section3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 3rem 3.5rem;
}
.container {
  justify-content: center;
  width: 45vw;
  display: flex;
  align-items: center;
  height: 100%;
}

.container-texto {
  height: 550px;
  padding: 100px 10px;
}
.container-texto p {
  width: 100%;
  height: 40px;
  font-size: 17px;
}
.container-texto h2 {
  font-size: 30px;
  font-weight: 800;
  color: #0fce33;
}
.container-texto h1 {
  font-size: 50px;
  font-weight: 700;
  color: white;
  letter-spacing: -0.2rem;
  line-height: 2.9rem;
  padding: 30px 0px 30px;
}

.container-texto p {
  font-size: 30px;
  font-weight: 300;
  color: #ffffff;
  line-height: 2rem;
  width: 100%;
}
.container-formulario {
  width: 100%;
  display: block;
  height: 530px;
  padding: 0px 50px;
}

.container-formulario p {
  font-size: 17px;
  text-align: center;
}
.form h1 {
  font-size: 30px;
  font-weight: 600;
  text-align: start;
  color: #380158;
}
.form {
  background-color: white;
  border: 0.5px solid #c9c9c9;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
  padding: 30px;
}

input {
  display: block;
  width: 100%;
  height: 50px;
  background: #f7f7f7;
  color: #606060;
  padding: 0px 14px;
  margin-top: 20px;
  border-radius: 5px;
}

select {
  display: block;
  width: 100%;
  height: 50px;
  background: #f7f7f7;
  padding: 0px 14px;
  margin-top: 20px;
  border-radius: 5px;
  color: #606060;
}

.button {
  font-size: 20px;
  font-weight: 600;
  color: white;
  display: block;
  width: 100%;
  height: 50px;
  background: #0aaa28;
  border-radius: 8px;
  opacity: 1;
  margin-top: 20px;
}

:root {
  --color-neutral-0: #26003b;
}
footer {
  width: 100%;
  height: 100%;
  opacity: 1;
}
.footer-contacts h1 {
  font-size: 40px;
  font-weight: 900;
}
.footer-contacts h2 {
  font-size: 30px;
  font-weight: 900;
}
.footer-contacts h3 {
  font-size: 22px;
  font-weight: 200;
}
.footer-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 3rem 3.5rem;
}
.footer-list {
  display: inline-block;
  padding-top: 40px;
}
.footer-list h3 {
  color: #6a016e;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}
.footer-list li a {
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.1rem;
  
}
.div-logo-footer {
  height: 200px;
}
.footer-list li {
  display: flex;
  width: 100%;
}
.footer-link {
  display: block;
  width: 100%;
}
.footer-link:hover {
  color: #6a016e;
  border: none;
}
.social {
  display: flex;
  justify-content: center;
}

.footer-icon {
  display: inline-block;
  text-align: center;
  width: 25%;
}

.footer-copyhigth {
  display: block;
  width: 100%;
  font-size: 20px;
  color: white;
  text-align: center;
  height: 50px;
}
@media screen and (max-width: 1168px) {
  .conteudo-blocos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1rem 7.5rem;
  }

  .main {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 3rem 3.5rem;
  }
  .conteudo h1 {
    margin-top: 30px;
    font-size: 50px;
    width: 60%;
  }
  conteudo p {
    font-size: 20px;
    text-align: justify;
    width: 60%;
  }

  .section3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 3rem 3.5rem;
    height: 100%;
  }

  #portal-client {
    display: flex;
    justify-content: center;
  }
  .garota {
    width: 400px;
    margin-top: 0px;
    margin-left: 180px;
    margin-bottom: -49px;
  }
}
@media screen and (max-width: 968px) {
  .footer-content {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  .section2 h1 {
    width: 100%;
    font-size: 40px;
    font-weight: 700;
  }
  .section2 p {
    width: 900px;
    font-size: 20px;
    font-weight: 200;
    padding: 10px;
    width: 100%;
  }
  
  li {
    font-size: 14px;
    width: initial;
    padding: 5px;
    font-weight: 700;
  }
  .contact {
    font-size: 15px;
    font-weight: 900;
    border-radius: 5px;
    opacity: 1;
    width: 170px;
    height: 50px;
    background-color: #0dc74f;
    margin-right: 40px;
  }
  .conteudo-blocos {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    padding: 1rem 10.5rem;
  }
  .bloco {
    padding: 20px;
    width: 260px;
    height: 350px;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 50px;
  }
  .bloco h2 span {
    font-size: 40px;
  }
  .bloco h1 {
    padding: 10px;
    font-size: 31px;
    font-weight: 800;
    color: #350053;
  }
  .portal-client {
    border-top-left-radius: 4px;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 4px;
    background-color: white;
    width: 350px;
    height: 420px;
    margin-left: -32px;
  }
  .client-img {
    width: 260px;
    margin-left: 60px;
    margin-bottom: 88px;
  }
}

@media screen and (max-width: 868px) {
  .conteudo-blocos {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    padding: 1rem 7.5rem;
  }
  .section3 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 3rem 3.5rem;
    height: 100%;
  }
  .form {
    background-color: white;
    border: 0.5px solid #c9c9c9;
    border-radius: 10px;
    opacity: 1;
    width: 80%;
    padding: 30px;
    margin-left: 10%;
  }
}

@media screen and (max-width: 768px) {
  .footer-content {
    grid-template-columns: repeat(1, 1fr);
  }

  .bloco {
    margin-left: 5%;
  }

  
  li {
    font-size: 10px;
    width: initial;
    padding: 5px;
    font-weight: 700;
  }
  .contact {
    font-size: 10px;
    font-weight: 900;
    border-radius: 5px;
    opacity: 1;
    width: 100px;
    height: 50px;
    background-color: #0dc74f;
    margin-right: 40px;
  }
  .conteudo h1 {
    margin-top: 30px;
    line-height: 2.8rem;
  }
  .conteudo p {
    font-size: 16px;
    text-align: justify;
    padding: 10px;
  }
  .button-aumentar-vendas {
    margin-left: 10px;
    font-size: 24px;
    width: 300px;
    margin-bottom: 100px;
  }

  .container-formulario {
    width: 100%;
    display: block;
    height: 530px;
    padding: 0px 0px;
  }
  .conteudo-blocos {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    padding: 1rem 2.5rem;
  }
  .garota {
    width: 400px;
    margin-left: 180px;
    margin-bottom: -49px;
  }
}

@media screen and (max-width: 558px) {
  .footer-content {
    grid-template-columns: repeat(1, 1fr);
  }
  .nav {
    display: none;
  }
  .footer-list {
    display: inline-block;
    padding: 0px;
  }
  .footer-copyhigth {
    display: block;
    width: 100%;
    font-size: 13px;
  }
  .footer-contacts h1 {
    font-size: 17px;
  }
  .footer-contacts h2 {
    font-size: 25px;
  }
  .footer-contacts h3 {
    font-size: 17px;
  }
  .footer-list h3 {
    font-size: 20px;
  }
  .footer-list li a {
    color: white;
    font-size: 16px;
  }
  .footer-icon img {
    width: 20px;
    height: 20px;
  }
  .section2 h1 {
    width: 100%;
    font-size: 40px;
    font-weight: 700;
  }
  #bloco-40-mb {
    transform: scale(1);
  }
  .conteudo-blocos {
    display: grid;
    grid-template-columns: repeat(1, 2fr);
    padding: 0rem 1rem;
  }
  .bloco {
    padding: 15px 30px;
    width: 319px;
    height: 410px;
    background-color: white;
    border-radius: 15px;
    margin-bottom: 50px;
  }
  .bloco h2 span {
    font-size: 65px;
  }
  .bloco h2 {
    font-weight: 900;
    color: #0fce33;
  }
  .bloco h1 {
    padding: 9px 3px;
    font-size: 39px;
    font-weight: 800;
    color: #350053;
  }
  .contact-bloco {
    font-size: 16px;
  }

  li {
    font-size: 10px;
    width: initial;
    padding: 4px;
    font-weight: 700;
  }

  .contact {
    font-size: 12px;
    font-weight: 900;
    border-radius: 5px;
    opacity: 1;
    width: 110px;
    height: 35px;
    background-color: #0dc74f;
    margin-right: 10px;
  }

  input {
    display: block;
    width: 100%;
    font-size: 15px;
    height: 38px;
    padding-left: 11px;
    color: #606060;
  }
  .form {
    background-color: white;
    border: 0.5px solid #c9c9c9;
    border-radius: 10px;
    opacity: 1;
    width: 341px;
    padding: 32px;
    margin-left: -32px;
  }

  #h1-form {
    font-size: 20px;
  }

  .container-texto p {
    font-size: 30px;
    margin-top: 10px;
    line-height: 2.1rem;
  }

  .container-texto h1 {
    padding: 5px 0px 5px;
  }

  .container-texto h2 {
    font-size: 28px;
  }

  .container-texto {
    padding: 50px 0px;
  }
  .container h2 {
    font-size: 40px;
    font-weight: 800;
    line-height: 2.1rem;
    color: #0fce33;
    text-shadow: 2px 2px 0px rgb(251, 251, 251);
  }
  ul,
  li {
    display: none;
  }

  .garota {
    width: 422px;
    margin-bottom: -48px;
    margin-left: 0px;
  }

  .main {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 3rem 2rem;
  }
  .portal-client {
    border-top-left-radius: 4px;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 4px;
    background-color: white;
    width: 335px;
    height: 384px;
    margin-left: -32px;
  }
  .client-img {
    width: 232px;
    margin-left: 60px;
    margin-bottom: 88px;
  }

  .logo {
    width: 316px;
  }

  .container h5 {
    font-size: 23px;
    color: #0fce33;
  }
  .container h3 {
    font-size: 17px;
    font-weight: 700;
    color: white;
    margin-bottom: 10px;
    margin-left: 50px;
  }
  .conteudo p {
    font-size: 16px;
    text-align: justify;
    padding: 10px;
    margin-left: 0px;
  }
  .conteudo h1 {
    margin-top: 0px;
    line-height: 3.3rem;
    font-size: 65px;
    text-shadow: 2px 2px 0px #0dc74f;
  }
  .contact-client {
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: 900;
    border-radius: 5px;
    opacity: 1;
    height: 50px;
    text-align: center;
    display: block;
    margin-left: 22px;
  }
  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
  }
  .price {
    color: darkorange;
    font-size: 32px;
    font-weight: 902;
    text-align: center;
    height: 36px;
    width: 214px;
    margin-left: 26px;
    padding: 0px;
    text-shadow: 1px 4px 1px red;
    margin-bottom: 12px;
  }
}

.contato-home-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 30vh;
}

.item-home-page {
  display: flex;
  width: 30%;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  color: #380158;
}

.item-home-page h2 {
  font-size: 1.4rem;
}

.item-home-page h1 {
  font-size: 1.4rem;

  font-weight: 800;
}

.icon-home-page {
  display: inline;
}

.imagemAssinante {
  width: 100%;
}
.resumo-da-empresa {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}

.resumo-da-empresa p {
  display: flex;
  text-align: justify;
  font-size: 16px;
  gap: 100px;
  width: 80vw;
  color: white;
}

.resumo-da-empresa h1 {
  font-size: 20px;
}
.div-logo-empresa {
  height: 200px;
}
.logo-resumo-empresa {
  width: 300px;
}

@media screen and (max-width: 668px) {
  .resumo-da-empresa p {
    display: flex;
    text-align: justify;
    font-size: 16px;
    gap: 100px;
    width: 90vw;
  }
  .item-home-page h1 {
    font-size: 14px;
    font-weight: 800;
  }
  .item-home-page h2 {
    font-size: 14px;
    line-height: 25px;
  }
}

footer {
  background-color: purple;
}

.section-cubos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 22%;
  gap: 30px;
}

.cubos {
  display: flex;
  width: 25%;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #380158;
  border-radius: 18px;
  /* background: linear-gradient(-45deg, #FB7C05, #800484); */
  height: 250px;
}

.cubos h2 {
  font-size: 20px;
  color: #fb7c05;
  padding: 5px;

}

.cubos h1 {
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  color: white;
  padding: 5px;
  width: 90%;
}

@media screen and (max-width: 668px) {
  .cubos {
    display: flex;
    width: 80%;
  }
 
  #assinante {
    display: none;
  }
 
  .footer-content {
    gap: 0rem;
}
  ul {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
