.plans {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.plan {
  background: white;
  color: #800484;
  width: 250px;
  padding: 2em;
  border-radius: 1em;
  margin: 0 0.5em;
  text-align: center;
  height: 450px;
  transition: 300ms;
}
.plan:hover {
  background: linear-gradient(-45deg, #fb7c052f, #80048410);
  color: #fff;
  box-shadow: 1px 0px 10px 1px white;
  transform: scale(1.03); /* Aplicando um zoom de 10% ao passar o mouse */
}
.rocket-cc {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: -50px;
  margin-right: -60px;
  position: relative;
}
.rocket {
  display: flex;
  width: 70px;
}
.plan__title {
  text-transform: uppercase;
  margin: 0 0 1em;
  padding-bottom: -20px;
  font-size: 2.5em;
}

.plan__subtitle {
  font-size: 1.2em;
}
.plan__price {
  font-size: 3.7rem;
  padding-top: 1rem;
  font-weight: 900;
  margin: 0;
}

.plan__price span {
  display: block;
  font-size: 1.5625rem;
  font-weight: 800;
  text-shadow: none;
  color: #fb7c05;
}

.plan__description {
  margin: 2em 0;
  line-height: 1.5;
}

.plan__button {
  background: #fb7c05;
  color: white;
  text-decoration: none;
  padding: 0.5em 0.75em;
  border-radius: 0.25em;
  text-transform: uppercase;
  font-weight: 700;
}

.color_white {
  background: grey;
}

.inverse {
  color: white;
  background: #4e4e4e;
}
