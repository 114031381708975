@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
    font-family: 'Roboto', sans-serif;
}

.slider {
  width: 100%;
  overflow: hidden;
}
.phone {
  display: none;
}

.slides {
  width: 100%;
  display: flex;
  background-color: #fb7c05;
}

.slides input {
  display: none;
}

.slide {
  width: 100%;
  position: relative;
}
img {
  max-width: 100vw;
}

.slide img {
  width: 100vw;
}

.manual-navigation {
  position: absolute;
  width: 100%;
 
  margin-top: -250px;
  display: flex;
  justify-content: space-between;
}

.buttons {
  width: 100px;
  background-color: transparent;
  border: none;
  color: white;
  margin: 5px;
  font-size: 15px;
  font-weight: 800;
}

.manual-btn {
  border: 2px solid white;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.arrows {
  color: rgba(255, 255, 255, 0.517);
  font-size: 100px;

}
.arrows:hover {
  transform: scale(1.1); /* Aplicando um zoom de 10% ao passar o mouse */
}
.manual-btn:not(:last-child) {
  margin-right: 20px;
}

.arrows:hover {
  color: white;
}

#radio1:checked ~ .first {
  margin-left: 0;
}
#radio2:checked ~ .first {
  margin-left: -100%;
}

.navigation-auto div {
  border: 2px solid #800484;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.5s;
}
.navigation-auto div:active {
  border: 2px solid #800484;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.1s;
  background-color: #800484;
}

.navigation-auto {
  position: absolute;
  width: 100%;
  margin-top: 360px;
  display: flex;
  justify-content: center;
}

.navigation-auto div:not(:last-child) {
  margin-right: 40px;
}

@media screen and (max-width: 558px) {
  .plans {
    gap: 27px;
  }
  .desktop {
    display: none;
  }
  .phone {
    display: flex;
  }
  
  .item-home-page h2 {
    font-size: 15px;
    line-height: 25px;
  }

  .item-home-page h1 {
    font-size: 13px;
    font-weight: 800;
  }
  .item-home-page {
    width: 33%;
  }
  .manual-navigation {
    position: absolute;
    width: 100%;
   
    margin-top: -140px;
    display: flex;
    justify-content: space-between;
  }
  .arrows {
    color: rgba(128, 0, 128, 0.415);
    font-size: 75px;
  
  }
  .navigation-auto {
    position: absolute;
    width: 100%;
    margin-top: 500px;
    display: flex;
    justify-content: center;
  }
}
