.help-section {
    text-align: center;
}
.help-section h1 {
    font-size: 3rem;
    color: white;
    padding-bottom: 30px;
    background: linear-gradient(-45deg, #FB7C05, #FB7C05,#a400a9);
    -webkit-background-clip: text; /* Aplicar gradiente apenas ao texto */
    background-clip: text; /* Aplicar gradiente apenas ao texto */
    color: transparent; /* Tornar o texto transparente */
    margin-top: 20px;
}

.item {
    display: inline-block;
    margin: 0 10px;
    vertical-align: top;
    transition: transform 0.3s ease; /* Adicionando uma transição de escala */
}

.item:hover {
    transform: scale(1.1); /* Aplicando um zoom de 10% ao passar o mouse */
}


.circle {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(-45deg, #FB7C05, #800484);
    overflow: hidden;
}

.circle img {
    width: 100%;
    height: auto;
}

.circle-p {
    background: linear-gradient(-45deg, #FB7C05, #FB7C05,#a400a9);
    -webkit-background-clip: text; /* Aplicar gradiente apenas ao texto */
    background-clip: text; /* Aplicar gradiente apenas ao texto */
    color: transparent; /* Tornar o texto transparente */
    font-weight: 600;
    font-size: 24px;
    margin: 10px 0;
}

@media screen and (max-width: 558px){
.circle {
    width: 140px;
    height: 140px;
}

.circle-p { 
    font-size: 18px;
 
}

.item {
    display: inline-block;
    margin: 10px 20px;
}
}